import React from "react";

// DropdownMenu.jsx// DropdownMenu.jsx remains the same in structure, just ensure it accepts and uses the prop:
const DropdownMenu = ({ dropdownObject, handleDropdownChange }) => (
  <>
    <p className="text-xs text-gray-500 font-montserrat italic">
      Set Expiry to{" "}
    </p>
    <select
      onChange={handleDropdownChange}
      className="p-1 mr-1 font-semibold font-montserrat focus:outline-none bg-transparent text-sm cursor-pointer"
    >
      {dropdownObject.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </>
);

export default DropdownMenu;
