// src/components/atoms/PlantAnimation/index.jsx
import React from "react";
import Lottie from "react-lottie";
import plantAnimation from "../../lottie-files/Plant.json";

const PlantAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: plantAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Lottie 
        options={defaultOptions} 
        height={24} 
        width={24}
        isClickToPauseDisabled={true}
      />
    </div>
  );
};

export default PlantAnimation;
